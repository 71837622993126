import Bluebird from 'bluebird'
import { Maybe } from 'graphql/jsutils/Maybe'
import hash from 'object-hash'
import { Durations } from '@/constants/durations'
import { fetchTitlesForPage, isRequiresTitleMapOnPage } from '@/page/TitleCollection'
import {
  getBrandLogos,
  getCollectionDataSources,
  getStartWatchingEpisodes,
  isFranchiseComponent,
  PageDataContext,
} from '@/services/RenderService'
import { Page } from '@/types/codegen-contentful'
import { isStagingEnvironment } from '@/utils/environment-utils'
import { logger } from '@/utils/logging'
import { omitUndefineds } from '@/utils/object'
import { isDefined } from '@/utils/types'
import { createWebClient } from '../ApolloClient'
import { cacheService } from '../CacheService'
import { getCollection, SNEAK_PEEK_ENTRY_ID } from '../CmsService/Collection'
import { getComingSoonCollection } from '../ComingSoonService'
import {
  CatalogTitle,
  CatalogTitleMap,
  findHydraExternalId,
  findTitleProjectSlug,
  formatTitleMap,
  isTitleLinkedToEpisodeWatchPage,
} from '../ContentCatalog'
import { getFranchiseBySlug } from '../FranchiseService'
import { isInGuildAccess } from '../PhaseManagerService'
import { getEpisode, getProjectAbout } from '../ProjectsService'

export interface PageDataContextResult extends PageDataContext {
  allQueriesSucceeded: boolean
}

export async function fetchPageDataContext(
  page: Maybe<Page>,
  preview: boolean = false,
  locale: string = 'en',
): Promise<PageDataContextResult | null> {
  if (!page || !page.contentCollection?.items || page.contentCollection?.items.length === 0) {
    return {
      allQueriesSucceeded: true,
      preview,
    }
  }

  const cacheKey = cacheService.genCacheKey('renderService.fetchPageDataContext', `${locale}-${preview}-${page.sys.id}`)
  const cached = await cacheService.get(cacheKey)
  if (cached) {
    return JSON.parse(cached)
  }

  const pageContext = await fetchDataSources(page, locale, preview)
  const context = omitUndefineds(pageContext)
  await cacheService.set(cacheKey, JSON.stringify(context), { expireInSeconds: Durations.FIFTEEN_MINUTES_IN_SECONDS })

  return context
}

async function fetchDataSources(page: Page, locale: string, preview: boolean): Promise<PageDataContextResult> {
  const dataSources = getCollectionDataSources(page)
  const pageContext: PageDataContextResult = {
    allQueriesSucceeded: true,
    preview,
  }

  const promises: Promise<void>[] = Array.from(dataSources).map(async (dataSource) => {
    if (dataSource === 'brand-logos') {
      const brandLogos = await getBrandLogos({ analytics: { page } })
      pageContext['brand-logos'] = brandLogos
      if (brandLogos.length === 0) pageContext.allQueriesSucceeded = false
    }
    if (dataSource === 'coming-soon') {
      const upcomingTabProjects = await getComingSoonCollection('FOCUS_SUMMARY')
      pageContext['coming-soon-tab'] = upcomingTabProjects
      if (upcomingTabProjects.length === 0) pageContext.allQueriesSucceeded = false
    }
    if (dataSource === 'early-access') {
      const sneakPeekProjects = await getCollection({ locale, preview }, SNEAK_PEEK_ENTRY_ID)
      pageContext['sneak-peeks'] = sneakPeekProjects
      if (!sneakPeekProjects) pageContext.allQueriesSucceeded = false
    }
    if (dataSource === 'start-watching') {
      const startWatchingEpisodes = await getStartWatchingEpisodes({ analytics: { page } })
      pageContext['start-watching'] = startWatchingEpisodes
      if (startWatchingEpisodes.length === 0) pageContext.allQueriesSucceeded = false
    }
  })

  promises.push(
    (async () => {
      const franchiseComponent = page.contentCollection?.items.find(isFranchiseComponent)
      if (!franchiseComponent || !franchiseComponent.franchiseSlug) return

      const franchise = await getFranchiseBySlug(franchiseComponent.franchiseSlug, true)
      if (franchise) {
        pageContext['franchiseComponent'] = franchiseComponent
        pageContext['franchise'] = franchise
      } else {
        pageContext.allQueriesSucceeded = false
      }
    })(),
  )

  promises.push(
    (async () => {
      if (isRequiresTitleMapOnPage(page)) {
        const titles = await fetchTitlesForPage(page)
        pageContext['title-map'] = await getValidatedTitleMap(titles)
        if (titles.length === 0) pageContext.allQueriesSucceeded = false
      }
    })(),
  )

  await Promise.all(promises)

  return pageContext
}

/**
 *
 * The data coming back from the Content Catalog is, unfortunately, often invalid.
 * We're patching this on the front-end for now by detecting and removing the invalid titles here.
 * @returns
 */
export async function getValidatedTitleMap(titles: CatalogTitle[]): Promise<CatalogTitleMap> {
  const ids = titles.map((t) => t.id).sort()
  const hashed = hash(ids, { algorithm: 'md5' })
  const cacheKey = cacheService.genCacheKey('renderService.getValidatedTitleMap', `title-ids-hash-${hashed}`)
  const cached = await cacheService.get(cacheKey)
  if (cached) {
    return JSON.parse(cached)
  }

  const client = createWebClient()
  const validated = await Bluebird.map(
    titles,
    async (title) => {
      const projectSlug = findTitleProjectSlug(title)

      if (!projectSlug) return

      try {
        const guid = findHydraExternalId(title)
        if (guid && isTitleLinkedToEpisodeWatchPage(title)) {
          return await validateEpisodeTitle(title, guid, projectSlug, client)
        } else {
          const response = await getProjectAbout({ slug: projectSlug }, client)
          if (response && response.slug === projectSlug) {
            const isInEarlyAccess = isInGuildAccess(response?.primaryFlowPhases ?? [])
            return { ...title, isInEarlyAccess }
          } else {
            reportWarning('project', title)
            return
          }
        }
      } catch (err) {
        reportWarning('episode', title, err as Error)
        return
      }
    },
    { concurrency: 3 },
  )

  const map = formatTitleMap(validated.filter(isDefined))
  await cacheService.set(cacheKey, JSON.stringify(map), { expireInSeconds: Durations.FIFTEEN_MINUTES_IN_SECONDS })

  return map
}

async function validateEpisodeTitle(
  title: CatalogTitle,
  guid: string,
  projectSlug: string,
  client: ReturnType<typeof createWebClient>,
): Promise<CatalogTitle | undefined> {
  const response = await getEpisode({ guid, projectSlug }, client)

  if (response && response.episode) {
    const earlyAccessDate = response.episode?.earlyAccessDate ? new Date(response.episode.earlyAccessDate) : null
    const publiclyAvailableDate = response.episode?.publiclyAvailableDate
      ? new Date(response.episode.publiclyAvailableDate)
      : null
    if (earlyAccessDate) {
      const now = new Date()
      const isInEarlyAccess = Boolean(
        !publiclyAvailableDate ||
          (earlyAccessDate && earlyAccessDate > now && publiclyAvailableDate && publiclyAvailableDate < now),
      )

      return { ...title, isInEarlyAccess }
    } else {
      return title
    }
  } else {
    reportWarning('episode', title)
    return
  }
}

function reportWarning(objType: string, title: CatalogTitle, err?: Error) {
  if (!isStagingEnvironment()) {
    logger().warn(`Unable to find a ${objType} from the federated graph for a Content Catalog title.`, { title }, err)
  }
}
