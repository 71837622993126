import {
  CatalogTitle,
  findTitleProjectSlug,
  formatTitleLikeVerticalImagePath,
  formatWatchPath,
} from '@/services/ContentCatalog'
import { LinkViewModel, LinksViewProps } from '@/services/RenderService'
import { getCloudinaryImageUrl } from '@/utils/Cloudinary'
import { logger } from '@/utils/logging'
import { Grid } from './Grid'
import { LargePortraitLink } from './LargePortraitLink'

export function LargePortraitGrid(props: LinksViewProps) {
  return (
    <Grid id={props.id} data-testid={`large-portrait-grid-${props.id}`}>
      {props.links.map((link) => (
        <LargePortraitLink key={link.name} link={link} />
      ))}
    </Grid>
  )
}

LargePortraitGrid.mapTitle = mapTitleToLink

export function mapTitleToLink(title: CatalogTitle): LinkViewModel | undefined {
  const imageUrl = getCloudinaryImageUrl({ path: formatTitleLikeVerticalImagePath(title), width: 294, height: 441 })
  const linkUrl = formatWatchPath(title)

  if (!imageUrl || !linkUrl) {
    logger().error(
      `A link view model cannot be created for this title because one or both of imageUrl and linkUrl could not be generated.`,
      { component: LargePortraitGrid.name, title, imageUrl, linkUrl },
    )
    return
  }

  return {
    name: title.title,
    alt: title.title,
    imageUrl,
    linkUrl,
    isInEarlyAccess: 'isInEarlyAccess' in title ? title.isInEarlyAccess : false,
    track: {
      eventName: 'Project Tile Clicked',
      payload: {
        name: title.title,
        slug: findTitleProjectSlug(title),
        label: title.description.short,
        tileType: 'large_portrait_grid',
        imageUrl,
        linkUrl,
      },
    },
  }
}
