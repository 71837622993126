import { TitleAndSubtitleDisplay } from '@/atoms/TitleAndSubtitleDisplay'
import {
  CatalogTitle,
  findTitleProjectSlug,
  formatWatchPath,
  formatTitleLikeLandscapeImagePath,
} from '@/services/ContentCatalog'
import { LinkViewModel, LinksViewProps } from '@/services/RenderService'
import { getCloudinaryImageUrl } from '@/utils/Cloudinary'
import { logger } from '@/utils/logging'
import { LandscapeGrid } from './LandscapeGrid'
import { LargeLandscapeLink } from './LargeLandscapeLink'

export function LargeLandscapeGrid(props: LinksViewProps) {
  return (
    <LandscapeGrid id={props.id} data-testid={`large-landscape-grid-${props.id}`}>
      {props.links.map((link) => (
        <div className="w-fit max-w-full" key={link.name}>
          <LargeLandscapeLink link={link} />
          {props.showTitleDescription && (
            <TitleAndSubtitleDisplay
              className="my-4 max-w-[490px]"
              title={link.name}
              subtitle={link.label}
              clampSubtitle
            />
          )}
        </div>
      ))}
    </LandscapeGrid>
  )
}

LargeLandscapeGrid.mapTitle = mapTitleToLink

export function mapTitleToLink(title: CatalogTitle): LinkViewModel | undefined {
  const imageUrl = getCloudinaryImageUrl({ path: formatTitleLikeLandscapeImagePath(title), width: 441, height: 294 })
  const linkUrl = formatWatchPath(title)

  if (!imageUrl || !linkUrl) {
    logger().error(
      `A link view model cannot be created for this title because one or both of imageUrl and linkUrl could not be generated.`,
      { component: LargeLandscapeGrid.name, title, imageUrl, linkUrl },
    )
    return
  }

  return {
    name: title.title,
    alt: title.title,
    label: title.description.short,
    imageUrl,
    linkUrl,
    track: {
      eventName: 'Project Tile Clicked',
      payload: {
        name: title.title,
        slug: findTitleProjectSlug(title),
        label: title.description.short,
        tileType: 'large_landscape_grid',
        imageUrl,
        linkUrl,
      },
    },
  }
}
