import React from 'react'
import classNames from 'classnames'
import { Image } from '@/atoms/Image'
import { InternalLink } from '@/atoms/InternalLink'
import { GuildAccessNotch } from '@/molecules/GuildAccessNotch'
import { useGuildMember } from '@/services/GuildUserService'
import { LinkViewModel } from '@/services/RenderService'
import { useSafeTrack } from '@/utils/analytics'

interface LargePortraitLinkProps {
  link: LinkViewModel
  className?: string
}

export function LargePortraitLink(props: LargePortraitLinkProps) {
  const track = useSafeTrack()
  const { isGuildMember } = useGuildMember()
  const { className } = props
  const handleClick = React.useCallback(() => {
    if (props.link.track) track(props.link.track?.eventName, props.link.track?.payload)
  }, [props.link.track, track])

  if (!props.link.imageUrl) return null

  return (
    <InternalLink
      href={props.link.linkUrl}
      aria-label={`project-tile-${props.link.name}`}
      className={classNames(
        'relative inline-block h-fit overflow-hidden rounded-lg duration-[400ms] ease-in-out hover:z-10 md:hover:scale-[1.05]',
        className,
      )}
      onClick={handleClick}
    >
      <Image
        aria-label={props.link.alt}
        alt={props.link.alt}
        src={props.link.imageUrl}
        height={490}
        width={294}
        className="rounded-lg bg-black"
      />
      <GuildAccessNotch isInEarlyAccess={props.link.isInEarlyAccess} isGuildMember={isGuildMember} />
    </InternalLink>
  )
}
