import { GuildAccessNotch } from '@/molecules/GuildAccessNotch'
import { Thumbnail } from '@/molecules/Thumbnail'
import { useGuildMember } from '@/services/GuildUserService'
import { LinkViewProps } from '@/services/RenderService'
import { formatCloudinaryPath } from '@/utils/Cloudinary/cloudinary'

export function LandscapeLink(props: LinkViewProps) {
  const { isGuildMember } = useGuildMember()

  return (
    <div className="relative overflow-hidden rounded-lg">
      <Thumbnail
        showTitle={false}
        className="mr-4"
        href={props.link.linkUrl}
        cloudinaryImagePath={formatCloudinaryPath(props.link.imageUrl) as string}
        title={props.link.alt}
        includePlayCircle={false}
      />
      <GuildAccessNotch isInEarlyAccess={props.link.isInEarlyAccess} isGuildMember={isGuildMember} />
    </div>
  )
}
